/* src/ImageGallery.css */

/* .gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; 
}

.gallery-item {
  flex: 1 1 calc(30% - 20px); 
  box-sizing: border-box;
} */

 .gallery {
      column-count: 3;
      column-gap: 10px;
      padding: 10px;
  }

  .gallery-item {
      break-inside: avoid;
      margin-bottom: 10px;
  }

.gallery-item img {
/*   width: 100%;
  height: auto;
  display: block;
  border-radius: 8px;
  transition: transform 0.3s ease; */
   width: 100%;
    cursor: pointer;
    border-radius: 5px;
    transition: transform 0.2s;
}

 .tall-img {
            height: 300px;
        }

.wide-img {
    height: 150px;
}

.gallery-item img:hover {
  transform: scale(1.05); /* Slight zoom on hover */
}
.image-gallery {
    margin: 45px 10px;
}
/* Responsive Styles */
@media (max-width: 768px) {
  .gallery-item {
    flex-basis: calc(33% - 20px);
}
  .gallery {
    column-count: 2;
  }
}
@media (max-width: 550px) {
  .gallery-item {
    flex-basis: calc(90% - 20px);
}
  .gallery {
    column-count: 1;
  }
  .tall-img, .wide-img {
    height: auto;
}
}
