.App {
    height:100%;
    width: 100%; 
    overflow-x: hidden;
  }
  
  .image_container{
    width: 100%;
    height: auto;
    max-width: 100%;
    position: relative;
  }
  .main_image {
    width: 100%;
    height: auto;
    max-width: 100%; 
    object-fit: fill;
    position: relative;
  }
  .image_container_txt_box{
    z-index: 6;
    background-color: rgba(12, 12, 12, 0.1);
    font-family: Poppins;
    height: auto;
    width: auto;
    color: rgb(255, 255, 255);
    text-decoration: none;
    white-space: nowrap;
    min-height: 0px;
    min-width: 0px;
    max-height: none;
    max-width: none;
    text-align: center;
    line-height: 7px;
    text-shadow: rgba(0, 0, 0, 0.25) 1px 1px 3px;
    letter-spacing: 1px;
    font-weight: 400;
    font-size: 11px;
    padding: 0px;
    border-radius: 20px;
    backdrop-filter: none;
    filter: none;
    transform-origin: 50% 50%;
    opacity: 1;
    transform: translate(0px, 0px);
    visibility: visible;
  }
  .image_container_txt_box marquee{
    height: 100%;
    width: 100%;
    line-height: 100%;
  }
  
  .centered {
    position: absolute;
    top: 30%;
    left: 30%;
    transform: translate(-50%, -50%);
    color: #ffffff;
    font-size: 50px;
    line-height:60px;
    letter-spacing: 1px;
    word-spacing: 2px;
    font-weight: 600;
  }
  @media (max-width: 1200px) {
    .main_image{
      width: 100%;
      height: auto;
      max-width: 100%;
      object-fit: fill;
  
      position: relative;
    }
    .centered{
      left: 20%;
    }
    .image_container_txt_box{
      padding: 0;
    }
  }
  @media (max-width: 768px) {
    .main_image{
      width: 100%;
      height: auto;
      max-width: 150%;
      object-fit: fill;
      position: relative;
    }
    .centered{
      top: 30%;
    }
    .image_container_txt_box{
     font-size: 2.4rem;
        line-height: 4rem;
    }
  }
  
  @media (max-width: 500px) {
  
    .main_image{
      width: 100%;
      height: auto;
      max-width: 100%;
      object-fit: fill;
      position: relative;
    }
      
    .centered{
      top: 35%;
        left: 15%;
    }
    .image_container_txt_box{
       font-size: 22px;
        line-height: 34px;
    }
      .info_container_2_right_img{
          margin-top: 40px;
      }
      .info_container_2_right {
    margin-left: 0;
}
  .main_image, .main_image_2{
      width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: 50% 50%;
  }
  }
 @media (max-width: 425px) {
     .image_container_txt_box {
        font-size: 19px;
     }
 }
  @media (max-width: 350px) {
  
    .image_container_txt_box{
     font-size: 15px;
        line-height: 2;
    }
  }
  @media (max-width: 200px) {
  
    .image_container_txt_box{
      font-size: 6px;
      line-height: 0;
    }
  }
