/* General Styles */
.footer_container {
    background-color: #f1f1f1;
    padding: 20px;
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 1;
    align-self: auto;
  }
  
  /* Grid Layout */
  .footer_content_section {
    display: grid;
    grid-template-columns: 1fr 1.2fr;
    gap: 20px;
    margin-top: 50px;
  }
  
  .footer_image_and_about {
    display: flex;
    flex-direction: column;
    padding-left: 200px;
  }
  
  .footer_figure {
    margin: 0;
    height: 37px;
    width: 140px;

  }
  
  .footer_image {
    max-width: 100%;
    height: auto;
  }
  
  .footer_image_box_content {
    margin-top: 16px;
    color: #8A8A8A;
    font-weight: 500;
    line-height: 1.4rem;
    letter-spacing: 0.03rem;
    word-spacing: 0.1rem;
    text-align: justify;
  }
  
  /* Grid for Other Sections */
  .footer_other {
    display: flex;
    justify-content: center;
    grid-column-gap: 80px;
    grid-row-gap: 0px;
    margin-top: 30px;
  }
  
  .footer_other_about {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: -12px;
  }
  
  .footer_other_header {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
  }
  
  .footer_other_about_list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .footer_other_about_list_item {
    margin: 5px 0;
  }
  
  .footer_other_about_list_item a {
    text-decoration: none;
    font-size: 0.9rem;
    font-weight: 400;
    letter-spacing: 0.02rem;
    color: #B4A061;
    fill: #B4A061;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .footer_copyright_section{
      color: #919191;
      font-size: 12px;
      font-weight: 300;
      display: flex;
      align-items: flex-start;
      padding-left: 200px;
  }

  .footer_copyright_divider{
    border-block-start: 1px solid #DCE0E0;
    margin: 50px 100px 30px 180px;
  }
  
  /* Responsive Styles */
 
@media (max-width: 1200px) {
    .footer_image_and_about{
      padding: 0px 10px 20px 100px;
    }
    .footer_copyright_section{
        padding: 20px 100px;
    }
}
@media (max-width: 768px) {
    .footer_image_and_about{
        padding: 16px 45px;
    }
    .footer_copyright_section{
        padding: 16px 45px;
    }
    .footer_content_section {
        grid-template-columns: 1fr;
        margin-top: 20px; /* Mobile view margin */
        margin-bottom: 20px;
    }
    
    .footer_other {
        display: grid;
        grid-template-columns: 1fr;
        margin-left: 14px;
    }
    .footer_copyright_divider{
        margin: 16px;
    }
}
@media (max-width: 500px) {
    .footer_image_and_about{
        padding: 14px 20px;
    }
    .footer_copyright_section{
        padding: 14px 20px;
    }
    .footer_other {
        display: grid;
        grid-template-columns: 1fr;
        margin-left: 14px;
    }
    .footer_copyright_divider{
        margin: 10px;
    }
}


/* Socila media icon */

/*
    Auther: Abdelrhman Said
*/

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:focus,
*:active {
  outline: none !important;
  -webkit-tap-highlight-color: transparent;
}

.wrapper{
    background: transparent;
    padding: 0;
    text-align: center;
    display: flex;
    margin-top: 14px; 
}

.wrapper .icon{
  position: relative;
  background-color: #ffffff;
  border-radius: 50%;
  margin: 15px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 22px;
  display: inline-block;
  align-items: center;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  color: #333;
  text-decoration: none;
}

.wrapper .tooltip {
  position: absolute;
  top: 0;
  line-height: 1.5;
  font-size: 14px;
  background-color: #ffffff;
  color: #ffffff;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background-color: #ffffff;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .icon:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.wrapper .icon:hover span,
.wrapper .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.wrapper .facebook:hover,
.wrapper .facebook:hover .tooltip,
.wrapper .facebook:hover .tooltip::before {
  background-color: #3b5999;
  color: #ffffff;
}

.wrapper .twitter:hover,
.wrapper .twitter:hover .tooltip,
.wrapper .twitter:hover .tooltip::before {
  background-color: #46c1f6;
  color: #ffffff;
}

.wrapper .instagram:hover,
.wrapper .instagram:hover .tooltip,
.wrapper .instagram:hover .tooltip::before {
  background-color: #e1306c;
  color: #ffffff;
}

.wrapper .github:hover,
.wrapper .github:hover .tooltip,
.wrapper .github:hover .tooltip::before {
  background-color: #333333;
  color: #ffffff;
}

.wrapper .youtube:hover,
.wrapper .youtube:hover .tooltip,
.wrapper .youtube:hover .tooltip::before {
  background-color: #de463b;
  color: #ffffff;
}

@media (max-width: 768px){

  .wrapper .icon{
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin: 6px;
  }
}