.core_strength_container{
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 1;
    align-self: auto;
    margin-top: 50px;
}
.projects .image-gallery {
    display: none;
}
.image_container{
    width: 100%;
    height: auto;
    max-width: 100%;
    position: relative;
  }

  .main_image {
    width: 90%;
    margin-left: 5%;
    height: auto;
    max-width: 100%; 
    object-fit: fill;
    position: relative;
    box-shadow: inset -10px -10px 15px rgba(255, 255, 255, 0.5), 
    inset 10px 10px 15px rgba(70, 70, 70, 0.12);
  }
  .main_image_2 {
    width: 100%;
    height: auto;
    max-width: 100%; 
    object-fit: fill;
    position: relative;
    margin-top: 50px;
  }
  .main_image_3 {
    width: 100%;
    height: auto;
    max-width: 100%; 
    object-fit: fill;
    position: relative;
    margin-top: 50px;
  }
  .main_image_4_box {
    position: absolute;
    bottom: 10%;
    width: 100%;
    color: #fff; 
    text-decoration: none;
    border-radius: 5px;
    cursor: pointer;
    z-index: 50;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease-in-out;
  }
.main_image_4{
    font-size: 1.5rem;
    font-weight: 600;
    height: 48px;
    padding: 0 10px;
    line-height: 40px;
    border-radius: 54px;
    background: #FFD700;
    opacity: 0.75;
    transition: 500ms all ease-in-out;
    filter: brightness(1.01) drop-shadow(2px 3px 4px black);
    border: none;
    user-select: none;
    cursor: pointer;
    transition: all 100ms linear;
    position: relative;
    box-shadow: inset 0 4px 6px -1px rgb(0 0 0 / 0.1), 
    0 2px 4px -2px rgb(0 0 0 / 0.1);
}


.main_image_4:active {
    box-shadow: inset 0 0 0 4px #043b3f6b;
}

.main_image_4::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #FFD700;
    border-radius: inherit;
    z-index: -1;
    top: 0;
    left: 0;
    transition: all 500ms ease-in-out;
    opacity: 0;
    animation: flash 100ms ease-in-out;
    transform: scaleX(1.75) scaleY(1.75);
}

.main_image_4:hover::before {
    opacity: 1;
    transform: scale(1.2);
}

@keyframes flash {
    0%{
        opacity: 1;
        transform: translate(45px);
    }
    100%{
        opacity: 0;
        transform: translate(0);
    }
}

.core_strength_header{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #897C4A;
    font-size: 1.65rem;
    font-weight: 600;
    line-height: 1.5rem;
    letter-spacing: 0.05rem;
    word-spacing: 0.15rem;
}

.core_strength_content_container {
    display: grid;
    padding: 10px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Increased to 250px */
    gap: 25px ;
}

.core_strength_content_box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 250px;
    width: 400px;
    overflow: hidden;
}
.core_strength_content_box_icon{
    height: 75px;
    width: 75px;
}
.core_strength_content_box_img{
    height: inherit;
    width: inherit;
}

.core_strength_content_box_item{
    display: block;
    box-sizing: border-box;
    unicode-bidi: isolate;
}

.core_strength_content_box_item_p{
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 2rem;
    letter-spacing: 0.08rem;
    word-spacing: 0.2rem;
}

@media (max-width: 1200px) {
    .core_strength_container{
        /* margin: 36px 100px; */
    }
}
@media (max-width: 768px) {
    .core_strength_container{
        margin: 44px 60;
    }
}
@media (max-width: 500px) {
    /* .core_strength_container{
        margin: 50px 20px;
    } */
    .core_strength_content_box{
        height: 225px;
    }
    .main_image{
        margin-top: -14px;
    }
    .main_image_4:before{
        width:auto;
    }
    .info_box_text{
        text-align: center;
    padding-right: 14px;
    }
    
}


/* Form */

.inquire_container{
    background-color: transparent;
    background-image: linear-gradient(180deg, var(#F3EEE7) 50%, var(#F3EEE7) 0%);
    margin: 20px 160px;
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 1;
    align-self: auto;
}

.modal_container{
    background: rgba(218,45,45,0.4);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(2px);
    border: 1px solid rgba(218,45,45,0.2);
    z-index: 90;
}
.modal_container_box{
    background: rgba(255,255,255,0.5) !important;
    -webkit-backdrop-filter: blur(10px) !important;
    backdrop-filter: blur(10px) !important;
    border: 1px solid rgba(160,160,160,0.25) !important;
}

.inquire_container_header{
    max-width: 100%;
    text-align: center;
    margin-bottom: 50px;
    box-sizing: border-box;
}

.inquire_container_header_content{
    color: white;
    font-size: 1.55rem;
    font-weight: 500;
    line-height: 1.5rem;
    letter-spacing: 0.05rem;
    word-spacing: 0.2rem;
    text-transform: capitalize;
    display: block;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    unicode-bidi: isolate;
}

.inquire_container_form_heading{
    height: 100%;
    max-width: 100%;
    text-align: left;
    margin-bottom: 20px;
    box-sizing: border-box;
 
}
.inquire_container_form_heading_text{
    color: #FFFFFF;
    font-size: 0.65rem;
    font-weight: 500;
    text-transform: none;
    line-height: 1.5rem;
    letter-spacing: 0.05rem;
    word-spacing: 0.2rem;
}

.input_form{
    margin-bottom: -15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    box-sizing: border-box;
    --widgets-spacing: 20px 20px;
}

.form_input_container{
    padding-right: calc(15px / 2);
    padding-left: calc(15px / 2);
    margin-bottom: 15px;
    padding-bottom: 10px;
    margin-right: 30px;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 4px;
    cursor: pointer;
}

.form_input_box{
    background-color: #F3F0ED;
    border-width: 1px 1px 1px 1px;
    border-radius: 10px 10px 10px 10px;
    font-size: 14px;
    font-weight: 300;
    color: #000000;
    letter-spacing: 2px;
    flex-basis: 100%;
    max-width: 100%;
    min-height: 47px;
    padding: 0 16px;
    width: 100%;
    border: 1px solid #69727d;
    vertical-align: middle;
    margin-top: 8px;
}

.form_input_label{
    margin-left: 8px;
}
/* .form_input_box_email{
    min-width: 150px;
    width: 350px;
} */

.form_submit_container{
    padding-right: calc(15px / 2);
    padding-left: calc(15px / 2);
    margin-bottom: 15px;
    padding-bottom: 10px;
    margin-right: 30px;
    margin-left: 30px;
    align-item: center;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 4px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.submit_btn{
    align-items: flex-end;
    background-color: #000000;
    color: #FFFFFF;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1.9px;
    border-radius: 10px 10px 10px 10px;
    cursor: pointer;
    font-family: "system-ui";
    font-size: 14px;
    padding: 10px 30px;
    transition: 1s;
    width: 250px;
    box-shadow: rgb(0, 0, 0) 0px 0px 0px 0px;
    margin-top: 16px;
    margin-left: -10px;

}

.submit_btn:hover{
    background-color: #a78311;
    color: #FFFFFF;
    border-style: none;
}

@media (max-width: 1200px) {
    .inquire_container{
        margin: 30px 150px;
    }
    .form_input_box{
        padding: 0 16px;
    }
    .main_image_4{
        height: 48px;
        padding: 0 10px;
        line-height: 40px;
        border-radius: 54px;
    }
}
@media (max-width: 768px) {
    .inquire_container{
        margin: 20px 20px;
    }
    .core_strength_content_box{
        height: 225px;
    }
    .input_form{
        margin: 0;
        display: block;
    }
    .form_input_box{
        padding: 10px;
       
    }
}
    @media (max-width: 500px) {
    .inquire_container{
        margin: 8px 20px;
    }
    .core_strength_content_box{
        height: 225px;
    }
    .input_form{
        margin: 0;
        display: block;
    }
   
    .main_image_4_box{
        bottom: 15px;
    }
    .main_image_4{
        height: 28px;
        line-height: 24px;
        font-size: 500;
        font-size: 1rem;
    }
}

@media (max-width: 430px) {
    .main_image_4_box{
        bottom: 12px;
    }
    .react-responsive-modal-modal{
        max-width: 284px;
    }
    .form_input_container, .inquire_container_form .input_form{
        margin: 0;
    }
    .submit_btn{
        margin-left: 0 !impotant;
        width:100%;
    }
    .inquire_container_form .input_form {
        margin-left: 0 !impotant;
    }

}
@media (max-width: 380px) {
    .main_image_4_box{
        bottom: 10px;
    }
    .main_image_4{
        font-size: 0.8rem;
        height: 24px;
        line-height: 24px;
    }
}
@media (max-width: 340px) {
    .main_image_4_box{
        bottom: 8px;
    }

}

/* infor box */

.info_box{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin-top: 66px;
    margin-left: 30px;
}

.info_box_text{
    color: #897C4A;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.5rem;
    letter-spacing: 0.05rem;
    word-spacing: 0.2rem;
}

.info_box_number{
    height:45px;
    width: 250px;
    margin: 50px 0px 50px 0px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 400;
    letter-spacing: 0.05rem;
    word-spacing: 5px;
    fill: #000000;
    color: #FFFFFF;
    background-color: #8c7004;
    border-radius: 10px 10px 10px 10px;
    box-sizing: border-box;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.info_box_number:hover{
    color: #FFFFFF;
    background-color: #000000;
}

@media (max-width: 500px){

    .info_box_number{
        margin-left: -40px;
    }
}
