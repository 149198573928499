/* styles.css */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100; /* Ensure the overlay is on top of other content */
  }
  
  .overlay img {
    max-width: 70%;
    max-height: 70%;
    border-radius: 10px;
  }
  .overlay_first_image{
    aspect-ratio: 6 / 1;
    object-fit: cover;
  }
  
  .close-btn {
    position: absolute;
    right: 23%;
    top: 18%;
    background: #fff;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .close-btn::before {
    content: '×';
    font-size: 20px;
    color: #333;
  }

  /* .click_me_btn {
    position: absolute;
    bottom: 20%;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    z-index: 1100;
  } */
  
  /* .click_me_btn {
    position: absolute;
    bottom: 10%;
    width: 230px;
    color: #fff; 
    text-decoration: none;
    border-radius: 5px;
    cursor: pointer;
    z-index: 102;
    transition: transform 0.3s ease-in-out;
  } */
  .click_me_btn_img:hover{
    transition: ease-in all 0.3s;
    transform: scale(1.20);
  }
  
  /* Coming soon css */

  @font-face {
    font-family: Clip;
    src: url("https://acupoftee.github.io/fonts/Clip.ttf");
  }
  
  .body_div {
    background-color: #141114;
    background-image: linear-gradient(335deg, black 23px, transparent 23px),
      linear-gradient(155deg, black 23px, transparent 23px),
      linear-gradient(335deg, black 23px, transparent 23px),
      linear-gradient(155deg, black 23px, transparent 23px);
    background-size: 58px 58px;
    background-position: 0px 2px, 4px 35px, 29px 31px, 34px 6px;
    
    position: absolute;
    top: 14%;
    text-align: center;
    color: #fff;
    z-index: 101;

  }
  
  .sign {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 0;
    height: 50%;
    background-image: radial-gradient(
      ellipse 50% 35% at 50% 50%,
      #6b1839,
      transparent
    );
    transform: translate(-50%, -50%);
    letter-spacing: 2;
    left: 50%;
    top: 50%;
    font-family: "Clip";
    text-transform: uppercase;
    font-size: 3em;
    font-weight: 600;
    word-spacing: 0.4em;

    color: linear-gradient(90deg, rgba(186,148,62,1) 0%, rgba(236,172,32,1) 20%, rgba(186,148,62,1) 39%, rgba(249,244,180,1) 50%, rgba(186,148,62,1) 60%, rgba(236,172,32,1) 80%, rgba(186,148,62,1) 100%);
    /* -webkit-background-clip: text; */
    /* -webkit-text-fill-color: transparent;	 */
    /* color: #ffe6ff;
    text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
      -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
      0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483; */
    /* animation: shine 2s forwards, flicker 3s infinite; */
  }

  .click_me_btn {
    position: absolute;
    bottom: 25%;
    width: 100%;
    color: #fff; 
    text-decoration: none;
    border-radius: 5px;
    cursor: pointer;
    z-index: 50;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease-in-out;
  }
  .click_me_btn_img{
    font-size: 1.5rem;
    font-weight: 600;
    height: 48px;
    padding: 0 20px;
    line-height: 40px;
    border-radius: 54px;
    background: #d9bb75;
    transition: 500ms all ease-in-out;
    filter: brightness(1.01) drop-shadow(2px 3px 4px black);
    border: none;
    user-select: none;
    cursor: pointer;
    transition: all 100ms linear;
    position: relative;
    box-shadow: inset 0 4px 6px -1px rgb(0 0 0 / 0.1), 
    0 2px 4px -2px rgb(0 0 0 / 0.1);
    box-shadow: inset 0 0 0 2px white, 0 0 0 5px transparent;
}
  
  @keyframes blink {
    0%,
    22%,
    36%,
    75% {
      color: #ffe6ff;
      text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
        -0.2rem 0.1rem 1rem #FFE55C, 0.2rem 0.1rem 1rem #FFE55C,
        0 -0.5rem 2rem #d9bb75, 0 0.5rem 3rem #d9bb75;
    }
    28%,
    33% {
      color: #FFE55C;
      text-shadow: none;
    }
    82%,
    97% {
      color: #d9bb75    ;
      text-shadow: none;
    }
  }
  
  .flicker {
    animation: shine 2s forwards, blink 3s 2s infinite;
  }
  
  .fast-flicker {
    animation: shine 2s forwards, blink 10s 1s infinite;
  }
  
  @keyframes shine {
    0% {
      color: #d9bb75;
      text-shadow: none;
    }
    100% {
      color: #ffe6ff;
      text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #FFE55C,
        -0.2rem 0.1rem 1rem #FFE55C, 0.2rem 0.1rem 1rem #FFE55C,
        0 -0.5rem 2rem #d9bb75, 0 0.5rem 3rem #d9bb75;
    }
  }
  
  @keyframes flicker {
    from {
      opacity: 1;
    }
  
    4% {
      opacity: 0.9;
    }
  
    6% {
      opacity: 0.85;
    }
  
    8% {
      opacity: 0.95;
    }
  
    10% {
      opacity: 0.9;
    }
  
    11% {
      opacity: 0.922;
    }
  
    12% {
      opacity: 0.9;
    }
  
    14% {
      opacity: 0.95;
    }
  
    16% {
      opacity: 0.98;
    }
  
    17% {
      opacity: 0.9;
    }
  
    19% {
      opacity: 0.93;
    }
  
    20% {
      opacity: 0.99;
    }
  
    24% {
      opacity: 1;
    }
  
    26% {
      opacity: 0.94;
    }
  
    28% {
      opacity: 0.98;
    }
  
    37% {
      opacity: 0.93;
    }
  
    38% {
      opacity: 0.5;
    }
  
    39% {
      opacity: 0.96;
    }
  
    42% {
      opacity: 1;
    }
  
    44% {
      opacity: 0.97;
    }
  
    46% {
      opacity: 0.94;
    }
  
    56% {
      opacity: 0.9;
    }
  
    58% {
      opacity: 0.9;
    }
  
    60% {
      opacity: 0.99;
    }
  
    68% {
      opacity: 1;
    }
  
    70% {
      opacity: 0.9;
    }
  
    72% {
      opacity: 0.95;
    }
  
    93% {
      opacity: 0.93;
    }
  
    95% {
      opacity: 0.95;
    }
  
    97% {
      opacity: 0.93;
    }
  
    to {
      opacity: 1;
    }
  }

 
  @media (max-width: 1200px) {

    .body_div{
        top: 24%;
      }

      .click_me_btn {
        bottom: 25%;
      }
  }
@media (max-width: 1024px){
     .close-btn{
    right: 17%;
    top: 22%;
     }
}
  @media (max-width: 1000px) {

    .body_div{
        top: 28%;
      }
      .click_me_btn{
        bottom: 25%;
    }
  }

  @media (max-width: 900px) {

    .body_div{
        top: 30%;
      }
      .click_me_btn{
        bottom: 25%;
    }
    }
  @media (max-width: 840px) {

    .body_div{
        top: 30%;
      }
      .click_me_btn{
        bottom: 30%;
    }
      .click_me_btn_img{
          font-size: 1rem;
         height: 42px;
      }
    }
  @media (max-width: 750px) {

    .body_div{
        top: 35%;
      }
      .click_me_btn{
        bottom: 25%;
    }
    }
  @media (max-width: 650px) {

    .body_div{
        top: 39%;
      }
      .sign{
        font-size: 2.1rem;
        font-weight: 500;
      }
      .click_me_btn{
        bottom: 25%;
    }
    .click_me_btn_img{
      font-size: 1.2rem;
      font-weight: 600;
      height: 30px;
      padding: 0 10px;
      line-height: 30px;
    }
    }
    
    @media (max-width: 550px) {
        
        .body_div{
            top: 40%;
          }
          .sign{
            font-size: 2rem;
            font-weight: 500;
          }
          .click_me_btn{
            bottom: 25%;
        }
  }
    @media (max-width: 500px) {
        
        .body_div{
            top: 40%;
          }
          .sign{
            font-size: 2rem;
            font-weight: 500;
          }
          .click_me_btn {
            bottom: 25%;
          }
          .click_me_btn_img {
            font-size: 1.0rem;
            font-weight: 600;
            height: 24px;
            padding: 0 20px;
            line-height: 24px;
          }

        .overlay img {
            width: 90%;
            max-width: 90%;
        }
        .close-btn{
            right: 8%;
    top: 22%;
        }
        
  }
    @media (max-width: 450px) {
        
        .body_div{
            top: 40%;
          }
          .sign{
            font-size: 1.8rem;
            font-weight: 500;
          }
          .click_me_btn {
            bottom: 28%;
          }
        .overlay img{
            max-height: 60%;
        }
  }
    @media (max-width: 400px) {
        
        .body_div{
            top: 42%;
          }
          .sign{
            font-size: 1.7rem;
            font-weight: 500;
          }
         
          .click_me_btn_img {
            font-size: 1rem;
            font-weight: 600;
            height: 20px;
            padding: 0 20px;
            line-height: 20px;
          }
  }
    @media (max-width: 320px) {
        
        .body_div{
            top: 44%;
          }
          .sign{
            font-size: 1.15rem;
            font-weight: 500;
          }
          
          .click_me_btn_img {
            font-size: 0.9rem;
            font-weight: 600;
            height: 20px;
            padding: 0 10px;
            line-height: 18px;
          }
  }
