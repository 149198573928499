.first_section {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns for left and right sections */
  gap: 70px;
  padding: 15px 150px 30px 90px;
}
.main_container{
  margin-top: 4 0px;
}

.first_section_left {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.about_box {
  color: #897c4a;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0.05rem;
  word-spacing: 0.2rem;
  margin: 30px 0 6px 0;
}

.first_section_left_img {
  width: 100%;
  vertical-align: middle;
  height: auto;
  margin-top: 20px;
  max-width: 100%;
  box-sizing: border-box;
  aspect-ratio: auto 1000 / 667;
  overflow-clip-margin: content-box;
  overflow: clip;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0px 30px 30px 0px rgba(0, 0, 0, 0.15);
}

.first_section_right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: justify;
  margin-top: 60px;
  padding: 3px 0 0 0;
}

.first_section_right_p {
  color: #616161;
  width: 95%;
  font-size: 1.05rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.04rem;
  word-spacing: 0.3rem;
  -webkit-text-stroke-color: #000;
  stroke: #000;
  padding: 3px 0 0 0;
  margin-bottom: 8px;
}

.third_section {
  color: #616161;
  line-height: 1.5rem;
  letter-spacing: 0.04rem;
  word-spacing: 0.3rem;
  -webkit-text-stroke-color: #000;
  stroke: #000;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 4px;
}

.about_icno_box {
  height: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 4px;
  margin-top: 14px;
}

.e_arrow_right {
  fill: #bea02e;
  transition: fill 0.3s;
  height: 12px;
  margin-right: 20px;
}
/* custom css*/
.first_section_right {
    align-items: flex-start;
}
.modal_container_box {
    -webkit-backdrop-filter: blur(10px) !important;
    backdrop-filter: blur(10px) !important;
    background: #22202080 !important;
}
.react-responsive-modal-modal {
    background: #fff;
    box-shadow: 0 12px 15px 0 #faf7f740;
    display: inline-block;
    margin: 1.2rem;
    max-width: 800px;
    overflow-y: auto;
    padding: 1.2rem;
    position: relative;
    text-align: left;
    vertical-align: middle;
    border-radius: 20px;
}
.inquire_container_form .input_form{
margin-left:30px;
}
.inquire_container_form .form_input_label {
    margin-left: 8px;
    color: #fff;
}
  .form_submit_container{
    align-items: center;
  }
.react-responsive-modal-closeButton svg{
fill: #ffffff;
}
p.desc {
    margin: 10px 0 20px;
}
p.desc span {
    display: block;
}
.main_map_sect{
        padding: 100px 100px 0;
    }
.main_map_sect {
    padding: 60px 100px;
    background: #efefef;
    grid-gap: 70px;
    display: grid;
    gap: 70px;
	grid-template-columns: 1fr 2fr; 
  justify-content: center;
}
.map_section2 li {
    margin-bottom: 20px;
}
.map_section1 {
    box-shadow: 0 30px 30px 0 #00000026;
}
.map_section2 h3 {
    font-size: 27px;
}
.main_image_4{
padding: 0 30px;
}
.main_image_cont_box{
	text-align:center;
align-items: center;
    border-radius: 5px;
    top: 20%;
    color: #fff;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    text-decoration: none;
    transition: transform .3s ease-in-out;
    width: 100%;
    z-index: 50;
    font-size: 75px;
    font-weight: 700;
}
.main_image_cont_box .first-line{
color: #d9bb75;
}
.main_image_4_box{
bottom: 20%;
}

.whatsapp-float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

svg.svg-inline--fa.fa-whatsapp{
	margin-top:16px;
}
p.second-line {
    font-size: 50px;
    margin-top: 25px;
}
p.third-line {
    font-size: 40px;
    margin-top: 30px;
}

/* Responsive Styles */
@media (max-width: 1380px) {
 .main_map_sect iframe {
    width: 650px;
  }
}

@media (max-width: 1200px) {
  .first_section {
    padding: 20px 20px;
    margin: 20px 0;
  }

  .first_section_right {
    margin-top: 30px;
	  align-items: center;
  }
 
	.first_section_left{
		 align-items: center;
	}
	
}
@media (max-width: 1200px) {
	.main_map_sect{
		padding: 60px 48px;
	}
	.main_map_sect iframe {
        width: 450px;
    }
}
@media (max-width: 768px) {
  .first_section {
    grid-template-columns: 1fr; /* Single column layout on mobile */
	 margin: 16px 0;
    padding: 6px 45px;
  }
.main_map_sect {
        grid-template-columns: 1fr;
        padding: 0;
    }
	.map_section2 {
    padding: 0 50px 45px;
}
  .main_map_sect iframe {
    width: 100%;
  }
  .first_section_right_p {
    margin-left: 14px; /* Margin left for mobile */
    margin-right: 20px; /* Margin right for mobile */
  }

  .first_section_left_img {
    max-width: 100%; /* Ensure image fits within container */
  }
  .image_container_txt_box {
        font-size: 2.5rem;
        line-height: 5rem;
    }
	p.second-line {
    font-size: 32px;
    margin-top: 15px;
}
	.main_image_cont_box .first-line {
    color: #d9bb75;
    font-size: 40px;
}
	p.third-line {
    font-size: 28px;
    margin-top: 15px;
}
	.main_image_3 {
    height: 450px;
}
}

@media (max-width: 500px) {
  .first_section {
    grid-template-columns: 1fr; /* Single column layout on mobile */
    padding: 1px 30px;
}
.first_section_right{
    margin: 0;
    margin-top: -14px;
    margin-left: -14px;
  }
  .first_section_right_p{
    letter-spacing: 0.04rem;
    word-spacing: 0.05rem;
  }
  .third_section{
    letter-spacing: 0;
    word-spacing: 0;
  }
  .e_arrow_right{
    margin-right: 10px;
    margin-top: 2px;
  }
      .image_container_txt_box {
        font-size: 17px;
        line-height: 40px;
    }
	.main_image_3 {
    height: 350px;
	}
	.main_image_cont_box .first-line {
    color: #d9bb75;
    font-size: 28px;
}
	p.second-line {
    font-size: 23px;
    margin-top: 15px;
}
	p.third-line {
    font-size: 22px;
    margin-top: 15px;
}
	    .main_map_sect iframe {
        height: 303px;
    }
	    .info_left_section {
        text-align: center;
    }
	.info_box{
		text-align: center;
    margin: 60px 20px;
	}
	.info_box_number {
        margin-left: 0;
	margin-bottom: 0;
    }
	.section_one_header h1{
		text-align: center;
	}
}

@media (max-width: 350px) {
    .image_container_txt_box {
        font-size: 16px;
        line-height: 2;
    }
	p.third-line {
    font-size: 18px;
    margin-top: 15px;
}
	p.second-line {
    font-size: 18px;
    margin-top: 15px;
}
	.main_image_cont_box .first-line {
    color: #d9bb75;
    font-size: 25px;
}
}
/* Info section */

.info_section {
  background-image: url("https://navrajindia.com/wp-content/uploads/2024/04/banner-about-us-1.jpg");
  background-attachment: fixed;
  background-size: cover; /* Cover the entire section */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat;
  margin-top: 50px;
  display: grid;
}

.info_section_box {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns for left and right sections */
  gap: 1%; /* Gap between columns */
  padding: 20px; /* Padding around the section */
  background-color: rgb(0 28 54 / 70%);
  padding-top: 100px;
  padding-bottom: 100px;
}

.info_left_section {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: flex-start;
  margin-left: 140px; /* Margin left for web */
}

.info_right_header {
  margin-bottom: 20px;
  color: #ffffff;
  font-size: 1.8rem;
  text-transform: uppercase;
  line-height: 3rem;
  letter-spacing: 0.04rem;
  word-spacing: 0.4rem;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: flex-start;
}

.info_right_content {
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0.04rem;
  word-spacing: 0.2rem;
  -webkit-text-stroke-color: #000;
  stroke: #000;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: flex-start;
}

.info_right_btn {
  color: white; /* Button text color */
  cursor: pointer; /* Pointer cursor on hover */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 210px;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.16rem;
  background-color: #ffffff;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: #ffffff;
  border-radius: 10px 10px 10px 10px;
  margin-top: 40px;
}
.info_right_btn_a{
  color: #111010;
    text-decoration: none;
}
.info_right_btn_a:hover{
  color: #000000 !important;
  background-color: #D1C292;
  border-color: #D1C292;
  font-weight: 600;
}

.info_right_btn:hover{
    color: #000000;
    background-color: #D1C292;
    border-color: #D1C292;
    font-weight: 600;
}

.info_right_section {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Gap between info_left_box elements */
  margin-left: 140px;
}

.info_left_box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #ffffff;
  margin-right: 30%;
    margin-bottom: 35px;
}

.info_left_header {
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.info_left_content {
  color: #ffffff;
  font-weight: 500;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .info_section {
    grid-template-columns: 1fr; /* Single column layout on mobile */
  }
  .info_section_box{
    gap: 30px;
  }
  .info_left_section{
    margin-left: 90px;
  }
  .info_left_box{
    margin-right: 10%;
    margin-bottom: 35px;
  }
}
@media (max-width: 768px) {
  .info_section {
    grid-template-columns: 1fr; /* Single column layout on mobile */
  }
  .info_section_box{
    gap: 20px;
  }
  .info_left_section{
    margin-left: 20px;
  }
  .info_left_box{
    margin-right: 20px;
    margin-bottom: 25px;
  }
}
@media (max-width: 500px) {
  .info_section {
    grid-template-columns: 1fr; /* Single column layout on mobile */
  }
  .info_section_box{
     grid-template-columns: 1fr;
     padding: 20px;
     gap: 50px;
  }
  .info_left_section{
    margin-right: 20px;
  }
  .info_right_header{
    font-size: 16px;
    font-weight: 600;
    line-height: 1.9em;
    letter-spacing: 1.2px;
    word-spacing: 0.1em;
  }
  .info_right_content{
    letter-spacing: 0.6px;
  }
  .info_right_section{
    margin-left: 20px;
    text-align: center;
    gap: 50px;
    margin-bottom: 50px;
  }
  .info_left_box{
    align-items: center;
  }
  .info_right_btn{
    margin-left: 80px;
    margin-top: 40px;
    margin-bottom: 20px;
  }
   .inquire_container_form .input_form {
        margin-left: 0 ;
    }
}

@media (max-width: 500px) {

  .info_right_btn{
    margin-left: 18%;
  }
}
