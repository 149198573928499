.App {
  height:100%;
  width: 100%; 
  overflow-x: hidden;
}

.image_container{
  width: 100%;
  height: auto;
  max-width: 100%;
  position: relative;
}
.main_image {
  width: 100%;
  height: auto;
  max-width: 100%; 
  object-fit: fill;
  position: relative;
}
.image_container_txt_box{
  z-index: 6;
  background-color: rgba(12, 12, 12, 0.1);
  font-family: Poppins;
  height: auto;
  width: auto;
  color: rgb(255, 255, 255);
  text-decoration: none;
  white-space: nowrap;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: center;
  line-height: 7px;
  text-shadow: rgba(0, 0, 0, 0.25) 1px 1px 3px;
  letter-spacing: 1px;
  font-weight: 400;
  font-size: 11px;
  padding: 0px;
  border-radius: 20px;
  backdrop-filter: none;
  filter: none;
  transform-origin: 50% 50%;
  opacity: 1;
  transform: translate(0px, 0px);
  visibility: visible;
}

.centered {
  position: absolute;
  top: 62%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  font-size: 24px;
  letter-spacing: 1px;
  word-spacing: 2px;
  font-weight: 600;
}
@media (max-width: 1200px) {
  .main_image{
    width: 100%;
    height: auto;
    max-width: 100%;
    object-fit: fill;

    position: relative;
  }
  .centered{
    left: 50%;
  }
  .image_container_txt_box{
    padding: 0;
  }
}
@media (max-width: 768px) {
  .main_image{
    width: 150%;
    height: auto;
    max-width: 150%;
    object-fit: fill;
    position: relative;
  }
  .centered{
    top: 70%;
  }
  .image_container_txt_box{
    font-size: 1rem;
  }
}

@media (max-width: 500px) {

  .main_image{
    width: 100%;
    height: auto;
    max-width: 100%;
    object-fit: fill;
    position: relative;
  }
  .centered{
    top: 75%;
  }
  .image_container_txt_box{
    font-size: 0.9rem;
  }
}