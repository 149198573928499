.leadership {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  column-gap : 50px;
}

.leadership_container {
    width: 100%;
    padding:100px 145px 100px 180px; /* Space around the container */
    box-sizing: border-box;
    padding-bottom: 24px;
}

.info_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 60px;
    grid-auto-flow: column;
    grid-template-areas: ". .";
    margin-bottom: 60px;
}
.info_container_left {
    width: 100%;
    height: auto;
    max-width: 100%;
    position: relative;
  justify-content: center;
}
.leadership_info_image{
    width: 100%;
    height: auto;
    max-width: 100%; 
    object-fit: fill;
    position: relative;
    border-radius: 30px;
    aspect-ratio: 16/12;
}
.info_container_right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.info_heading{
    color: #000000;
    font-size: 1.5rem;
    font-weight: 500;
    font-style: italic;
    line-height: 2rem;
    letter-spacing: 0.01rem;
    word-spacing: 0.1rem;
    margin-bottom: 32px;
}
.info_sub_heading_box{
    
    color: #897C4A;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.3rem;
    letter-spacing: 0.01rem;
    word-spacing: 0.1rem;
    margin-bottom: 32px;    
}
.info_sub_heading_content{
    text-align: justify;
    color: #000000;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.04rem;
    word-spacing: 0.3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: background .3s, border .3s, border-radius .3s, box-shadow .3s, transform var(--e-transform-transition-duration, .4s);
}
.info_sub_heading_content_one{
    margin-bottom: 18px;
}


/* Reponsive */

/* Adjust for smaller screens */
@media (max-width: 1400px) {

    .leadership_container{
        padding: 100px 20px;
    }
}
@media (max-width: 1200px) {
    .leadership_container{
        padding: 80px;
    }
    .info_container{
        gap: 40px;
    }
    .info_container_right{
        margin-top: -20px;
    }
    
}
@media (max-width: 1000px) {
    .info_container {
        grid-template-columns: 1fr; /* Stack columns on top of each other */
        grid-auto-flow: row; /* Change flow direction to row for stacking */
        grid-template-areas: 
        "item1"
        "item2";
    }
    
}
@media (max-width: 768px) {
    .leadership_container {
        padding: 40px;
        padding-bottom: 0;
    }
    .info_container {
        grid-template-columns: 1fr; /* Stack columns on top of each other */
        grid-auto-flow: row; /* Change flow direction to row for stacking */
        grid-template-areas: 
        "item1"
        "item2";
    }
    .info_container_right{
        margin-top: -20px;
    }
}

/* Additional adjustments for very small screens */
@media (max-width: 480px) {
    .leadership_container{
        padding: 30px;
      padding-bottom: 0;
    }
    .info_container {
        gap: 25px; /* Optionally reduce gap for very small screens */
    }
    .info_container_right{
        margin-top: -20px;
    }
}
